import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useVenue } from '../../hooks/venues'
import { venues } from '../../types/Venue'
import { resetLocale } from '../../utils/i18n'

const Reset = () => {
  const navigate = useNavigate()
  const kioskId = document.documentElement.dataset['kuuid']
  const venue = useVenue()

  useEffect(
    () => {
      let queryParam = ''
      if (venue === venues.grandTheatre) {
        queryParam = `?kuuid=${kioskId}` || ''
      }
      resetLocale()
      navigate(`../${queryParam}`)
    },
    [
      navigate,
      kioskId,
      venue,
    ],
  )

  return null
}

export default Reset
