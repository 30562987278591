import { useCallback, useLayoutEffect, useRef, useState } from 'react'

type UseCarousel = <TItem>(
  items: TItem[] | null,
  displayTimeMs: number,
  bypassAutoChange?: (item: TItem) => boolean,
) => [TItem | null, () => void]

const useCarousel: UseCarousel = (items, displayTimeMs, bypassAutoChange) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const timeoutIdRef = useRef<number | undefined>(undefined)

  const moveNext = useCallback(
    () => {
      setCurrentIndex(currentIndex => {
        if (!items) return 0

        let nextIndex = currentIndex + 1

        if (nextIndex >= items.length) {
          nextIndex = 0
        }

        return nextIndex
      })
    },
    [
      items,
    ],
  )

  useLayoutEffect(
    () => {
      window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined

      if (!items) return

      const currentItem = items[currentIndex]
      if (!currentItem) {
        moveNext()
        return
      }

      if (bypassAutoChange && bypassAutoChange(currentItem)) return

      const timeoutId = window.setTimeout(
        () => {
          timeoutIdRef.current = undefined

          moveNext()
        },
        displayTimeMs,
      )

      timeoutIdRef.current = timeoutId

      return () => {
        window.clearTimeout(timeoutId)
      }
    },
    [
      items,
      currentIndex,
      moveNext,
      bypassAutoChange,
      displayTimeMs,
    ],
  )

  const item = items?.[currentIndex] ?? null

  return [item, moveNext]
}

export {
  useCarousel,
}
