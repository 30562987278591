import { Venue, venues } from '../types/Venue'

import { getPathname } from './location'

const getVenue = (): Venue => {
  const path = getPathname() ?? ''

  if (path.startsWith(`/${venues.grandTheatre}`)) return venues.grandTheatre
  if (path.startsWith(`/${venues.mtelus}`)) return venues.mtelus

  // Legacy behavior: no route prefix, default to MTelus, the original version.
  return venues.mtelus
}

export {
  venues,
  getVenue,
}
