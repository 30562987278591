import { bearerToken as grandTheatreQcBearerToken } from '../../constants/apis/grandTheatreQc'
import { bearerToken as mtelusBearerToken } from '../../constants/apis/mtelus'
import { apiHost } from '../../constants/apis/telus'
import { timeouts } from '../../constants/timeouts'
import { getKiosk } from '../../utils/kiosks'
import { getVenue, venues } from '../../utils/venues'

const getRequestData = (): [string, string] => {
  const venue = getVenue()

  const baseUri = `${apiHost}/${venue}`

  switch (venue) {
    case venues.grandTheatre:
      return [baseUri, grandTheatreQcBearerToken]

    case venues.mtelus:
    default:
      return [baseUri, mtelusBearerToken]
  }
}

const setKioskParameter = (path: string, kioskId: string | null) => {
  const url = new URL(path) || ''
  const venue = getVenue()
  if (venue === venues.grandTheatre) {
    url.searchParams.append('kuuid', kioskId ?? '')
  }

  return url
}

const get = (path: string) => {
  const [baseUri, bearerToken] = getRequestData()

  const controller = new AbortController()

  const timeoutId = setTimeout(
    () => controller.abort(),
    timeouts.apiRequestMs,
  )
  const url = setKioskParameter(
    `${baseUri}${path.startsWith('/') ? path : `/${path}`}`,
    getKiosk(),
  )
  return fetch(url,
    {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
      signal: controller.signal,
    },
  )
    .then(response => {
      clearTimeout(timeoutId)
      return response
    })
    .catch((error: Error) => {
      clearTimeout(timeoutId)
      throw error
    })
}

const post = (path: string) => {
  const [baseUri, bearerToken] = getRequestData()

  const controller = new AbortController()

  const timeoutId = setTimeout(
    () => controller.abort(),
    timeouts.apiRequestMs,
  )
  const url = setKioskParameter(
    `${baseUri}${path.startsWith('/') ? path : `/${path}`}`,
    getKiosk(),
  )

  return fetch(url,
    {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
      signal: controller.signal,
    },
  )
    .then(response => {
      clearTimeout(timeoutId)
      return response
    })
    .catch((error: Error) => {
      clearTimeout(timeoutId)
      throw error
    })
}

export {
  get,
  post,
}
