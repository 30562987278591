import { Trans } from '@lingui/macro'

import FixedTranslationProvider from '../../../components/FixedTranslationProvider'
import leftDecoration from '../assets/left-decoration.svg'
import rightDecoration from '../assets/right-decoration.svg'

import styles from './MtelusScanPrompt.module.css'

const prompt =
  <Trans>
    Scan your MTELUS+ passport
  </Trans>

const MtelusScanPrompt = () => {
  return (
    <div className={styles['content']}>
      <div className={styles['decorative-arrow-container']}>
        <img
          className={styles['decorative-arrow']}
          alt="Flèche descendante décorative/Decorative downward arrow"
          src={leftDecoration}
        />
      </div>
      <div className={styles['prompt-container']}>
        <hr className={`${styles['separator']} ${styles['thick']}`} />
        <p className={`${styles['prompt']} ${styles['fr']}`}>
          <FixedTranslationProvider locale="fr-CA">
            {prompt}
          </FixedTranslationProvider>
        </p>
        <hr className={styles['separator']} />
        <p className={`${styles['prompt']} ${styles['en']}`}>
          <FixedTranslationProvider locale="en-CA">
            {prompt}
          </FixedTranslationProvider>
        </p>
        <hr className={styles['separator']} />
      </div>
      <div className={styles['decorative-arrow-container']}>
        <img
          className={styles['decorative-arrow']}
          alt="Flèche descendante décorative/Decorative downward arrow"
          src={rightDecoration}
        />
      </div>
    </div>
  )
}

export default MtelusScanPrompt
