import { Trans } from '@lingui/macro'

import BorderedPage from '../../components/BorderedPage'
import { IconType } from '../../components/IconProps'
import { usePrize } from '../../hooks/prize'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue, venues } from '../../hooks/venues'

import styles from './Won.module.css'

const Won = () => {
  useRedirectToHomeTimeout()

  const venue = useVenue()
  const prize = usePrize()

  let congratulationsPrompt
  let claimPrompt
  switch (venue) {
    case venues.grandTheatre:
      congratulationsPrompt =
        <>
          Vous remportez le prix instantané suivant{' '}:
        </>

      claimPrompt =
        <>
          Nous vous invitons à récupérer votre prix
          {' '}{prize?.redeemLocation}{' '}
          en présentant votre code PRIMO.
          <br />
          <br />
          TELUS vous souhaite un bon spectacle!
        </>

      break

    case venues.mtelus:
    default:
      congratulationsPrompt =
        <Trans>
          Congratulations! You’ve won a prize:
        </Trans>

      claimPrompt =
        <Trans>
          Claim it by presenting your MTELUS+ Passport {prize?.redeemLocation}.
          Valid today only.
        </Trans>

      break
  }

  return (
    <BorderedPage icon={IconType.OpenGift}>
      <div className={styles['container']}>
        <h1 className={styles['header']}>
          <Trans>
            CONGRATULATION!
          </Trans>
        </h1>
        <div className={styles['congratulations-container']}>
          <p className={styles['congratulations']}>
            {congratulationsPrompt}
          </p>
        </div>
        <p className={styles['prize-name']}>
          {prize?.name ?? ''}
        </p>
        <div className={styles['details-container']}>
          <p className={styles['details']}>
            {claimPrompt}
          </p>
        </div>
      </div>
    </BorderedPage>
  )
}

export default Won
