import { useLayoutEffect, useMemo, useState } from 'react'

type Dimension = {
  width: number,
  height: number,
}

const useWindowDimension = (): Dimension => {
  const [windowHeight, setWindowHeight] = useState<number>(0)
  const [windowWidth, setWindowWidth] = useState<number>(0)

  useLayoutEffect(
    () => {
      const determineWindowHeight = () => {
        setWindowHeight(window.innerHeight)
        setWindowWidth(window.innerWidth)
      }

      determineWindowHeight()

      window.addEventListener('resize', determineWindowHeight)

      return () => {
        window.removeEventListener('resize', determineWindowHeight)
      }
    },
    [],
  )

  const dimension = useMemo(
    () => {
      return {
        width: windowWidth,
        height: windowHeight,
      }
    },
    [
      windowWidth,
      windowHeight,
    ],
  )

  return dimension
}

export type WindowSize = {
  height: number,
  width: number,
}

const useWindowSize = (): WindowSize => {
  const windowDimension = useWindowDimension()

  const size = useMemo(
    () => {
      return {
        height: windowDimension.height,
        width: windowDimension.width,
      }
    },
    [
      windowDimension,
    ],
  )

  return size
}

const useAdjustBodyStyleVars = () => {
  const size = useWindowSize()

  useLayoutEffect(
    () => {
      const body = document.querySelector('body')
      if (!body) return

      body.style.setProperty('--height', `${size.height}px`)
      body.style.setProperty('--width', `${size.width}px`)
    },
    [
      size.height,
      size.width,
    ],
  )
}

export {
  useAdjustBodyStyleVars,
}
