
import { CheckIn, CheckInDto, mapFromDto } from '../types/CheckIn'

import { post } from './apis/telusApi'

const getCheckIn = async (userId: string, eventId: number): Promise<CheckIn> => {
  const query = new URLSearchParams()

  query.append('user_events[id]', String(eventId))

  return post(`/users/${userId}/events.json?${query.toString()}`)
    .then(response => {
      return response
        .json()
        .catch(() => {
          return response
            .text()
            .catch((error: Error) => {
              throw new Error(
                `Unexpected response ` +
                `${response.status} (${response.statusText}) ` +
                `- ${error.message}`,
              )
            })
            .then(content => {
              throw new Error(
                `Unexpected response: ` +
                `${response.status} (${response.statusText}) ` +
                `- ${content}`,
              )
            })
        })
    })
    .then((dto: CheckInDto) => mapFromDto(dto))
}

export {
  getCheckIn,
}
