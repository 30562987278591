import { ReactNode } from 'react'

import { useVenue, venues } from '../hooks/venues'
import { Locale } from '../utils/i18n'

import FixedTranslationProvider from './FixedTranslationProvider'

type BilingualContentRenderData = Readonly<{
  mainLang: ReactNode,
  secondLang: ReactNode,
}>

type FrCaProps = Readonly<{
  content: ReactNode,
  mainLang?: undefined,
  secondLang?: undefined,
  children: (data: BilingualContentRenderData) => ReactNode,
}>

type CustomProps = Readonly<{
  content: ReactNode,
  mainLang: Locale,
  secondLang: Locale,
  children: (data: BilingualContentRenderData) => ReactNode,
}>

type Props =
  | FrCaProps
  | CustomProps

const BilingualContent = (props: Props) => {
  const venue = useVenue()

  const mainLang = props.mainLang ?? 'fr-CA'
  const secondLang = props.secondLang ?? 'en-CA'

  let mainLangElement
  let secondLangElement
  switch (venue) {
    case venues.grandTheatre:
      mainLangElement =
        <FixedTranslationProvider locale={mainLang}>
          {props.content}
        </FixedTranslationProvider>

      secondLangElement =
        null

      break

    case venues.mtelus:
    default:
      mainLangElement =
        <FixedTranslationProvider locale={mainLang}>
          {props.content}
        </FixedTranslationProvider>

      secondLangElement =
        <FixedTranslationProvider locale={secondLang}>
          {props.content}
        </FixedTranslationProvider>

      break
  }

  return (
    <>
      {props.children({
        mainLang: mainLangElement,
        secondLang: secondLangElement,
      })}
    </>
  )
}

export default BilingualContent
