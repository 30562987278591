import FullScreenVideo from '../../components/FullScreenVideo'
import { usePrize } from '../../hooks/prize'
import { useRedirect } from '../../hooks/redirection'

import drawLost from './assets/draw-lost.mp4'
import drawWon from './assets/draw-won.mp4'

const Suspense = () => {
  const prize = usePrize()
  const redirectTo = useRedirect()

  const handlePlaybackDone = () => {
    if (prize) {
      redirectTo.won(prize)
    } else {
      redirectTo.lost()
    }
  }

  const video = prize
    ? drawWon
    : drawLost

  return (
    <FullScreenVideo
      src={video}
      onPlaybackDone={handlePlaybackDone}
    />
  )
}

export default Suspense
