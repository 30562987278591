import { Event, EventsDto, mapFromDto } from '../types/Event'
import { isEventCurrent } from '../utils/events'

import { get } from './apis/telusApi'

const getCurrentEvents = (): Promise<Event[] | null> => {
  return get(`/events/today.json`)
    .then(response => {
      if (response.status !== 200) {
        return response
          .text()
          .catch((error: Error) => {
            throw new Error(
              `Unexpected status code: ` +
              `${response.status} (${response.statusText}) ` +
              `- ${error.message}`,
            )
          })
          .then(content => {
            throw new Error(
              `Unexpected status code: ` +
              `${response.status} (${response.statusText}) ` +
              `- ${content}`,
            )
          })
      }

      return response.json()
    })
    .then((dto: EventsDto) => mapFromDto(dto))
    .then(events => events?.filter(isEventCurrent) ?? null)
}

export {
  getCurrentEvents,
}
