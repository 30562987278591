import { Trans } from '@lingui/macro'

import BorderedPage from '../../components/BorderedPage'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue } from '../../hooks/venues'
import { getVenueName } from '../../types/Venue'

import styles from './NoEvents.module.css'

const NoEvents = () => {
  useRedirectToHomeTimeout()

  const venue = useVenue()

  return (
    <BorderedPage>
      <div className={styles['container']}>
        <h1 className={styles['header']}>
          <Trans>
            There is no prize available for now
          </Trans>
        </h1>
        <p className={styles['message']}>
          <Trans>
            Try your luck again at the kiosk on your next visit at {getVenueName(venue)}.
            <br />
            <br />
            TELUS wish you a good show!
          </Trans>
        </p>
      </div>
    </BorderedPage>
  )
}

export default NoEvents
