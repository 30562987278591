import { SyntheticEvent, useRef, useState } from 'react'

import { useRedirect } from '../../hooks/redirection'
import { useVenue, venues } from '../../hooks/venues'

import styles from './ScanPrompt.module.css'
import GrandTheatreQcScanPrompt from './scanPrompts/GrandTheatreQcScanPrompt'
import MtelusScanPrompt from './scanPrompts/MtelusScanPrompt'

const ScanField = () => {
  const redirectTo = useRedirect()

  const [userId, setUserId] = useState('')

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleInputRef = (ref: HTMLInputElement | null) => {
    if (!ref) return
    inputRef.current = ref

    ref.focus()
  }

  const handleUserIdChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setUserId(event.currentTarget.value)
  }

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    redirectTo.codeRead(userId)
  }

  const handleFocusLost = () => {
    inputRef.current?.focus()
  }

  return (
    <form
      className={styles['scan-form']}
      onSubmit={handleSubmit}
    >
      <input
        ref={handleInputRef}
        value={userId}
        onChange={handleUserIdChange}
        onBlur={handleFocusLost}
      />
      <button type="submit" />
    </form>
  )
}

const ScanPrompt = () => {
  const venue = useVenue()

  let prompt
  switch (venue) {
    case venues.grandTheatre:
      prompt = <GrandTheatreQcScanPrompt />
      break

    case venues.mtelus:
    default:
      prompt = <MtelusScanPrompt />
      break
  }

  return (
    <>
      <div className={styles['container']}>
        {prompt}
      </div>
      <ScanField />
    </>
  )
}

export default ScanPrompt
