import { Media, MediaDto, mapFromDto } from '../types/Media'

import { get } from './apis/telusApi'

const getMedia = (): Promise<Media[]> => {
  return get(`/media.json`)
    .then(response => response.json())
    .then((dtos: MediaDto[]) => dtos.map(mapFromDto))
}

export {
  getMedia,
}
