import { tryGetItem } from './utils'

const apiHostKey = 'REACT_APP_API_HOST'

const apiHost =
  tryGetItem('apiHost', apiHostKey)
  ?? process.env[apiHostKey]
  ?? ''

export {
  apiHost,
}
