import { useEffect, useState } from 'react'

import FullScreenVideo from '../../components/FullScreenVideo'
import { timeouts } from '../../constants/timeouts'
import { getMedia } from '../../fetchers/media'
import { useCarousel } from '../../hooks/carousel'
import { Media } from '../../types/Media'
import { reload } from '../../utils/location'

import styles from './Carousel.module.css'

const pictureDisplayTimeMs = 15_000

const videoExtensions = ['mp4']

const isVideo = (media: Media) => videoExtensions.includes(media.extension)

type PictureProps = {
  media: Media,
}

const Picture = (props: PictureProps) => {
  return (
    <img
      className={styles['picture']}
      alt={props.media.title}
      src={props.media.url}
    />
  )
}

const Carousel = () => {
  const [media, setMedia] = useState<Media[] | null>(null)
  const [videoCounter, setVideoCounter] = useState(0)

  const [currentMedia, moveNext] = useCarousel(
    media,
    pictureDisplayTimeMs,
    isVideo,
  )

  const handlePlaybackDone = () => {
    setVideoCounter(
      videoCounter =>
        videoCounter === Number.MAX_SAFE_INTEGER
          ? 0
          : videoCounter + 1,
    )

    moveNext()
  }

  useEffect(
    () => {
      getMedia()
        .then(media => setMedia(media))
        .catch((error: Error) => {
          console.error(error)

          setTimeout(
            () => reload(),
            timeouts.mediaFailureRetryMs,
          )
        })
    },
    [],
  )

  if (!media) return null
  if (!currentMedia) return null

  return (
    <div className={styles['container']}>
      {
        isVideo(currentMedia)
          ? (
            <FullScreenVideo
              key={videoCounter}
              src={currentMedia.url}
              onPlaybackDone={handlePlaybackDone}
            />
          )
          : <Picture media={currentMedia} />
      }
    </div>
  )
}

export default Carousel
