import { useCallback, useEffect, useRef } from 'react'

import { timeouts } from '../constants/timeouts'

import { useRedirect } from './redirection'

const voidHandler = () => {}

const useActionTimeout = (action: () => unknown, timeoutMs: number) => {
  const timeoutIdRef = useRef<number | undefined>(undefined)

  useEffect(
    () => {
      window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined

      const timeoutId = window.setTimeout(action, timeoutMs)

      timeoutIdRef.current = timeoutId

      return () => {
        window.clearTimeout(timeoutId)
      }
    },
    [
      action,
      timeoutMs,
    ],
  )
}

const useRedirectToHomeTimeout = (timeoutMs?: number) => {
  const redirectTo = useRedirect()
  const redirect = process.env['REACT_APP_DISABLE_REDIRECTION_TIMEOUTS'] === 'true'
    ? voidHandler
    : redirectTo.home

  useActionTimeout(
    redirect,
    timeoutMs ?? timeouts.endPageMs,
  )
}

/**
 * Allows to configure an action dynamically that will be fired either
 * immediately or after the given timeout has passed, whichever comes last.
 * If no action is configured by the time the timeout expires, the action will
 * be triggered immediately. If an action if configured before the timeout,
 * expires, the action will only be executed when the timeout expires.
 * @param timeoutMs The minimum amount of time in milliseconds that must pass
 * before the configured action can be fired.
 * @returns A function that allows to set an action to be triggered when
 * appropriate.
 */
const useConfigureActionWithTimeoutGuard = (timeoutMs: number) => {
  const timeoutIdRef = useRef<number | undefined>(undefined)
  const redirectToRef = useRef<(() => unknown) | undefined>(undefined)

  const setRedirect = useCallback(
    (redirectTo: () => unknown) => {
      redirectToRef.current = redirectTo

      if (!timeoutIdRef.current) {
        redirectToRef.current()
      }
    },
    [],
  )

  useEffect(
    () => {
      window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined

      const timeoutId = window.setTimeout(
        () => {
          timeoutIdRef.current = undefined

          if (redirectToRef.current) {
            redirectToRef.current()
          }
        },
        timeoutMs,
      )

      timeoutIdRef.current = timeoutId

      return () => {
        window.clearTimeout(timeoutId)
      }
    },
    [
      timeoutMs,
    ],
  )

  return setRedirect
}

export {
  useRedirectToHomeTimeout,
  useConfigureActionWithTimeoutGuard,
}
