const venues = {
  mtelus: 'mtelus',
  grandTheatre: 'legrandtheatre',
} as const

export type Venue = typeof venues[keyof typeof venues]

export const getVenueName = (venue: Venue) => {
  switch (venue) {
    case venues.grandTheatre: return `Grand Théâtre`
    default: return `MTelus`
  }
}

export {
  venues,
}
