export type Media = {
  title: string,
  extension: string,
  url: string,
}

export type MediaDto = {
  title: string,
  extension: string,
  url: string,
}

export const mapFromDto = (dto: MediaDto): Media => {
  return {
    ...dto,
  }
}
