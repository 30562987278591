const tryGetItem = (key: string, defaultValue: string): string | null => {
  const value = document.body.dataset[key]

  if (value === undefined) return null

  return value === defaultValue
    ? null // Expect the value to be interpolated during deployment.
    : value
}

export {
  tryGetItem,
}
