import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Prize } from '../types/CheckIn'

import { useKiosk } from './kiosks'
import { useVenue, venues } from './venues'

const formatPrizeQuery = (prize: Prize) =>
  `prize=${prize.name}&redeemLocation=${prize.redeemLocation}`

type RedirectAndLinkFn<TFn> =
  & TFn
  & {
    link: string,
  }

type RedirectTo = {
  home: RedirectAndLinkFn<() => unknown>,
  codeRead: (code: string) => unknown,
  events: (code: string) => unknown,
  suspense: (prize: Prize | null) => unknown,
  won: (prize: Prize) => unknown,
  lost: () => unknown,
  alreadyCheckedIn: () => unknown,
  invalid: () => unknown,
  networkError: () => unknown,
  noEvents: () => unknown,
}

const useRedirect = (): RedirectTo => {
  const doNavigate = useNavigate()
  const venue = useVenue()
  const kioskId = useKiosk()

  const navigate = useCallback(
    (path: string) => doNavigate(`/${venue}/${path}`),
    [
      venue,
      doNavigate,
    ],
  )

  const redirectTo = useMemo(
    () => {
      const homeFn = () => navigate(`reset`)
      let queryParam = ''
      let queryAddParam = ''
      if (venue === venues.grandTheatre) {
        queryParam = `?kuuid=${kioskId}` || ''
        queryAddParam = `&kuuid=${kioskId}` || ''
      }
      homeFn.link = `/${venue}/reset${queryParam}`

      const redirectTo: RedirectTo = {
        home: homeFn,
        codeRead: (code) =>
          navigate(`code-read?code=${code}${queryAddParam}`),
        events: (code) =>
          navigate(`events?code=${code}${queryAddParam}`),
        suspense: (prize) =>
          navigate(
            prize
              ? `suspense?${formatPrizeQuery(prize)}${queryAddParam}`
              : `suspense${queryParam}`,
          ),
        won: (prize) =>
          navigate(`won?${formatPrizeQuery(prize)}${queryAddParam}`),
        lost: () =>
          navigate(`lost${queryParam}`),
        alreadyCheckedIn: () =>
          navigate(`already-checked-in${queryParam}`),
        invalid: () =>
          navigate(`invalid${queryParam}`),
        networkError: () =>
          navigate(`network-error${queryParam}`),
        noEvents: () =>
          navigate(`no-events${queryParam}`),
      }

      return redirectTo
    },
    [
      venue,
      kioskId,
      navigate,
    ],
  )

  return redirectTo
}

export {
  useRedirect,
}
