import primoPrompt from '../assets/primo-prompt.svg'

import styles from './GrandTheatreQcScanPrompt.module.css'

const GrandTheatreQcScanPrompt = () => {
  return (
    <div>
      <img
        className={styles['prompt']}
        src={primoPrompt}
        alt="Balayez votre passeport Primo"
      />
    </div>
  )
}

export default GrandTheatreQcScanPrompt
