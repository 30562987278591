const timeouts = {
  endPageMs: 10_000,
  readSuccessMs: 2_000,
  apiRequestMs: Number(process.env['REACT_APP_API_REQUEST_TIMEOUT']) || 2_000,
  eventSelectionTimeoutMs: 30_000,
  mediaFailureRetryMs: 10_000,
} as const

export {
  timeouts,
}
