import { Event } from '../types/Event'

import { getNow } from './time'

const msInSec = 1000
const msInMin = 60 * msInSec

// 120 minutes, 2h00
const currentBeforeMs = 120 * msInMin
const currentAfterMs = 120 * msInMin

const isEventCurrent = (event: Event) => {
  const now = getNow().getTime()
  const startTime = event.startDate.getTime()

  // Negative if event is in the future,
  // Positive if event is passed,
  // Zero if event is literally now.
  const delta = now - startTime

  if (delta === 0) {
    return true
  } else if (delta < 0) {
    // Event is in the future.
    return Math.abs(delta) <= currentBeforeMs
  } else {
    // Event is done.
    return delta <= currentAfterMs
  }
}

export {
  isEventCurrent,
}
