import { useMemo } from 'react'

import { Prize } from '../types/CheckIn'

import { useQuery } from './query'

const usePrize = (): Prize | null => {
  const query = useQuery()

  const prize = useMemo(
    () => {
      const prizeName = query.get('prize')
      const redeemLocation = query.get('redeemLocation')

      const prize: Prize | null =
        prizeName !== null &&
        redeemLocation !== null
          ? {
            name: prizeName,
            redeemLocation,
          }
          : null

      return prize
    },
    [
      query,
    ],
  )

  return prize
}

export {
  usePrize,
}
