const getKiosk = () => {
  const params = new URLSearchParams(window.location.search)
  const kioskId = params.get('kuuid') ?? ''

  return kioskId
}

export {
  getKiosk,
}
