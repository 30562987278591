import { Locale } from '../utils/i18n'

export type Prize = {
  name: string,
  redeemLocation: string,
}

export enum CheckInStatus {
  DrawWon,
  DrawLost,
  AlreadyCheckedIn,
  Error,
}

type DrawWon = {
  status: CheckInStatus.DrawWon,
  locale: Locale,
  prize: Prize,
}

type DrawLost = {
  status: CheckInStatus.DrawLost,
  locale: Locale,
}

type AlreadyCheckedIn = {
  status: CheckInStatus.AlreadyCheckedIn,
  locale: Locale,
}

type Error = {
  status: CheckInStatus.Error,
  locale?: undefined,
}

export type CheckIn =
  | DrawWon
  | DrawLost
  | AlreadyCheckedIn
  | Error

type LanguagePreference =
  | 'fr'
  | 'en'

const mapToLocale = (language: LanguagePreference): Locale => {
  if (language.includes('fr')) return 'fr-CA'

  return 'en-CA'
}

type PrizeDto = {
  name: string,
  redeem_type: string,
}

type DrawWonDto = {
  draw_status: 'draw_won',
  status: undefined,
  language_preference: LanguagePreference,
  prize: PrizeDto,
}

type DrawLostDto = {
  draw_status: 'draw_lost',
  status: undefined,
  language_preference: LanguagePreference,
}

type AlreadyCheckedInDto = {
  draw_status: 'draw_already_checked_in',
  status: undefined,
  language_preference: LanguagePreference,
}

type ErrorDto = {
  draw_status: undefined,
  status: 'unprocessable_entity',
}

export type CheckInDto =
  | DrawWonDto
  | DrawLostDto
  | AlreadyCheckedInDto
  | ErrorDto

export const mapFromDto = (dto: CheckInDto): CheckIn => {
  if (dto.status === 'unprocessable_entity') {
    return {
      status: CheckInStatus.Error,
    }
  }

  switch (dto.draw_status) {
    case 'draw_won':
      return {
        status: CheckInStatus.DrawWon,
        locale: mapToLocale(dto.language_preference),
        prize: {
          name: dto.prize.name,
          redeemLocation: dto.prize.redeem_type,
        },
      }

    case 'draw_lost':
      return {
        status: CheckInStatus.DrawLost,
        locale: mapToLocale(dto.language_preference),
      }

    case 'draw_already_checked_in':
      return {
        status: CheckInStatus.AlreadyCheckedIn,
        locale: mapToLocale(dto.language_preference),
      }

    default:
      return {
        status: CheckInStatus.Error,
      }
  }
}
