import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = (): URLSearchParams => {
  const location = useLocation()

  const query = useMemo(
    () => {
      return new URLSearchParams(location.search)
    },
    [
      location.search,
    ],
  )

  return query
}

export {
  useQuery,
}
