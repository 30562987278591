import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { loadLocales, resetLocale } from './utils/i18n'

import './normalize.css'
import './index.css'

const loadApp = () => {
  loadLocales()
  resetLocale()

  document.addEventListener(
    'dragstart',
    (event) => event.preventDefault(),
    false,
  )

  const rootElement = document.getElementById('root')
  if (!rootElement) return

  const reactRoot = createRoot(rootElement)

  reactRoot.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )
}

loadApp()
