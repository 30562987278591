import { useEffect, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Link } from 'react-router-dom'

import BorderedPage from '../../components/BorderedPage'
import { timeouts } from '../../constants/timeouts'
import { getCheckIn } from '../../fetchers/checkIns'
import { getCurrentEvents } from '../../fetchers/events'
// import { useKiosk } from '../../hooks/kiosks'
import { useQuery } from '../../hooks/query'
import { useRedirect } from '../../hooks/redirection'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue } from '../../hooks/venues'
import { CheckInStatus } from '../../types/CheckIn'
import { Event } from '../../types/Event'
import { Venue, venues } from '../../types/Venue'
import { Locale, setLocale } from '../../utils/i18n'

import styles from './Events.module.css'

const voidHandler = () => {}

const venueLocaleOverrides = new Map<Venue, Locale>([
  [venues.grandTheatre, 'fr-CA'],
])
const Events = () => {
  useRedirectToHomeTimeout(timeouts.eventSelectionTimeoutMs)

  const query = useQuery()
  const venue = useVenue()
  const redirectTo = useRedirect()

  const [events, setEvents] = useState<Event[] | null>(null)

  const userId = query.get('code')

  useEffect(
    () => {
      if (userId == null) {
        redirectTo.invalid()
        return
      }
    },
    [
      userId,
      redirectTo,
    ],
  )

  const handleEventSelectionRef = useRef<(event: Event) => unknown>(voidHandler)

  handleEventSelectionRef.current = (event: Event) => {
    if (userId == null) return

    getCheckIn(userId, event.id)
      .then(checkIn => {
        const locale =
          venueLocaleOverrides.get(venue)
          ?? checkIn.locale
          ?? 'fr-CA'

        switch (checkIn.status) {
          case CheckInStatus.DrawWon:
            redirectTo.suspense(checkIn.prize)
            setLocale(locale)
            break

          case CheckInStatus.DrawLost:
            redirectTo.suspense(null)
            setLocale(locale)
            break

          case CheckInStatus.AlreadyCheckedIn:
            redirectTo.alreadyCheckedIn()
            setLocale(locale)
            break

          case CheckInStatus.Error:
          default:
            redirectTo.invalid()
            break
        }
      })
      .catch((error: Error) => {
        console.error(error)
        redirectTo.networkError()
      })
  }

  useEffect(
    () => {
      if (userId == null) return

      getCurrentEvents()
        .then(events => {
          if (events === null) {
            redirectTo.noEvents()
            return
          }

          if (events.length === 0) {
            redirectTo.noEvents()
            return
          }

          const firstEvent = events[0]
          if (events.length === 1 && firstEvent) {
            handleEventSelectionRef.current(firstEvent)
          } else {
            setEvents(events)
          }
        })
        .catch((error: Error) => {
          console.error(error)
          redirectTo.networkError()
        })
    },
    [
      userId,
      redirectTo,
    ],
  )

  if (userId == null) return null
  if (events === null) return null

  return (
    <BorderedPage>
      <div className={styles['container']}>
        <div className={styles['prompt']}>
          Sélectionnez votre spectacle
        </div>
        <ScrollContainer
          className={styles['venues-container']}
          vertical={true}
          horizontal={false}
          hideScrollbars={false}
        >
          {
            events.map(event => (
              <div
                key={event.id}
                className={styles['venue-button-container']}
              >
                <button
                  data-testid="venue-button"
                  className={styles['venue-button']}
                  onClick={() => handleEventSelectionRef.current(event)}
                >
                  <div className={styles['venue-button-content']}>
                    {event.name}
                  </div>
                </button>
              </div>
            ))
          }
        </ScrollContainer>
        <div className={styles['actions-container']}>
          <Link
            className={styles['cancel-button']}
            to={redirectTo.home.link}
          >
            Annuler
          </Link>
        </div>
      </div>
    </BorderedPage>
  )
}

export default Events
