import { Trans } from '@lingui/macro'

import BorderedPage from '../../components/BorderedPage'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue, venues } from '../../hooks/venues'

import styles from './AlreadyCheckedIn.module.css'

const AlreadyCheckedIn = () => {
  useRedirectToHomeTimeout()

  const venue = useVenue()

  let header
  let message
  switch (venue) {
    case venues.grandTheatre:
      header =
        <>
          Votre code a déjà été soumis
        </>

      message =
        <>
          Le code PRIMO ne peut être balayé à la borne qu’une seule fois par visite.
          <br />
          <br />
          Si vous avez gagné un prix, vérifiez vos messages texte pour connaître le lot et le lieu de récupération.
        </>

      break

    case venues.mtelus:
    default:
      header =
        <Trans>
          Your participation has already been registered today.
        </Trans>

      message =
        <Trans>
          We are very happy to have you here. We hope you will have a
          great evening with us.
        </Trans>

      break
  }

  return (
    <BorderedPage>
      <div className={styles['container']}>
        <h1 className={styles['header']}>
          {header}
        </h1>
        <p className={styles['message']}>
          {message}
        </p>
      </div>
    </BorderedPage>
  )
}

export default AlreadyCheckedIn
