import styles from './FullScreenVideo.module.css'

type VideoProps = {
  src: string,
  onPlaybackDone: () => unknown,
}

const FullScreenVideo = (props: VideoProps) => {
  return (
    <video
      className={styles['video']}
      src={props.src}
      autoPlay={true}
      muted={true}
      onAbort={props.onPlaybackDone}
      onEmptied={props.onPlaybackDone}
      onEnded={props.onPlaybackDone}
      onError={props.onPlaybackDone}
      onStalled={props.onPlaybackDone}
    />
  )
}

export default FullScreenVideo
