import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useKiosk } from './hooks/kiosks'
import { useVenue } from './hooks/venues'
import { useAdjustBodyStyleVars } from './hooks/window'
import AlreadyCheckedIn from './pages/alreadyCheckedIn/AlreadyCheckedIn'
import CodeRead from './pages/codeRead/CodeRead'
import Events from './pages/events/Events'
import Home from './pages/home/Home'
import Invalid from './pages/invalid/Invalid'
import Lost from './pages/lost/Lost'
import NetworkError from './pages/networkError/NetworkError'
import NoEvents from './pages/noEvents/NoEvents'
import Reset from './pages/reset/Reset'
import Suspense from './pages/suspense/Suspense'
import Won from './pages/won/Won'

const AppRoutes = () => {
  const venue = useVenue()
  const kioskId = useKiosk()

  useEffect(
    () => {
      document.documentElement.dataset['theme'] = venue
      document.documentElement.dataset['kuuid'] = kioskId
    },
    [
      venue,
      kioskId,
    ],
  )

  return (
    <Routes>
      <Route
        path="/code-read"
        element={<CodeRead />}
      />
      <Route
        path="/events"
        element={<Events />}
      />
      <Route
        path="/suspense"
        element={<Suspense />}
      />
      <Route
        path="/won"
        element={<Won />}
      />
      <Route
        path="/lost"
        element={<Lost />}
      />
      <Route
        path="/already-checked-in"
        element={<AlreadyCheckedIn />}
      />
      <Route
        path="/invalid"
        element={<Invalid />}
      />
      <Route
        path="/network-error"
        element={<NetworkError />}
      />
      <Route
        path="/no-events"
        element={<NoEvents />}
      />
      <Route
        path="/reset"
        element={<Reset />}
      />
      <Route
        path="/"
        element={<Home />}
      />
    </Routes>
  )
}

const VenueRoutes = () => {
  return (
    <Routes>
      <Route
        path="/legrandtheatre/*"
        element={<AppRoutes />}
      />
      <Route
        path="/mtelus/*"
        element={<AppRoutes />}
      />
      <Route
        path="/*"
        element={<AppRoutes />}
      />
    </Routes>
  )
}

const App = () => {
  useAdjustBodyStyleVars()

  return (
    <I18nProvider i18n={i18n}>
      <BrowserRouter>
        <VenueRoutes />
      </BrowserRouter>
    </I18nProvider>
  )
}

export default App
