const getNow = () => new Date()

const getRemainingTimeMsUntilMidnight = (now: Date) => {
  const tomorrowMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    0,
    0,
  )

  const remainingTimeMsUntilMidnight =
    tomorrowMidnight.getTime() - now.getTime()

  return remainingTimeMsUntilMidnight
}

export {
  getNow,
  getRemainingTimeMsUntilMidnight,
}
