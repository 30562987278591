import closedGift from '../assets/icons/grandTheatreQc/closed-gift.svg'
import openGift from '../assets/icons/grandTheatreQc/open-gift.svg'
import thumbsUp from '../assets/icons/grandTheatreQc/thumbs-up.svg'

import styles from './Icon.module.css'
import { IconProps, IconType } from './IconProps'

const Icon = (props: IconProps) => {
  switch (props.icon) {
    case IconType.ThumbsUp:
      return (
        <img
          className={styles[props.icon]}
          alt="Icône de pouce levé/Thumbs up icon"
          src={thumbsUp}
        />
      )

    case IconType.OpenGift:
      return (
        <img
          className={styles[props.icon]}
          alt="Icône de cadeau ouvert/Open gift box icon"
          src={openGift}
        />
      )

    case IconType.ClosedGift:
      return (
        <img
          className={styles[props.icon]}
          alt="Icône de cadeau fermé/Closed gift box icon"
          src={closedGift}
        />
      )

    default:
      return null
  }
}

export default Icon
