const reload = () => {
  window.location.reload(
    // @ts-ignore Non-standard Firefox implementation, hard refresh if available.
    true,
  )
}

const getPathname = (): string | null => {
  return window.location.pathname ?? null
}

export {
  reload,
  getPathname,
}
