import { tryGetItem } from './utils'

const apiBearerTokenKey = 'REACT_APP_MTELUS_API_BEARER_TOKEN'

const bearerToken =
  tryGetItem('mtelusApiBearerToken', apiBearerTokenKey)
  ?? process.env[apiBearerTokenKey]
  ?? ''

export {
  bearerToken,
}
