import { Trans } from '@lingui/macro'

import BorderedPage from '../../components/BorderedPage'
import { IconType } from '../../components/IconProps'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue, venues } from '../../hooks/venues'

import styles from './Lost.module.css'

const headerMtelus =
  <Trans>
    Better luck<br />next time.
  </Trans>

const headerGrandTheatre =
  <Trans>
    Better luck<br />next time
  </Trans>

const textMtelus =
  <Trans id="pages.lost.message">
    We are very happy to have you here. We hope you will have
    a great evening with us.
  </Trans>

const textGrandTheatre =
  <>
    Tentez à nouveau votre chance à la borne lors de votre prochaine visite au Grand Théâtre.
    <br />
    <br />
    TELUS vous souhaite un bon spectacle!
  </>

const Lost = () => {
  useRedirectToHomeTimeout()

  const venue = useVenue()

  let header
  let text
  switch (venue) {
    case venues.grandTheatre:
      header = headerGrandTheatre
      text = textGrandTheatre
      break

    default:
      header = headerMtelus
      text = textMtelus
      break
  }

  return (
    <BorderedPage icon={IconType.ClosedGift}>
      <div className={styles['container']}>
        <h1 className={styles['header']}>
          {header}
        </h1>
        <p className={styles['message']}>
          {text}
        </p>
      </div>
    </BorderedPage>
  )
}

export default Lost
