import { I18nProvider } from '@lingui/react'
import { ReactNode } from 'react'

import { Locale, getFixedI18n } from '../utils/i18n'

type Props = {
  locale: Locale,
  children: ReactNode,
}

const FixedTranslationProvider = (props: Props) => {
  const i18n = getFixedI18n(props.locale)

  return (
    <span lang={props.locale}>
      <I18nProvider i18n={i18n}>
        {props.children}
      </I18nProvider>
    </span>
  )
}

export default FixedTranslationProvider
