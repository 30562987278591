import { useVenue, venues } from '../hooks/venues'

import IconGrandTheatreQc from './IconGrandTheatreQc'
import IconMtelus from './IconMtelus'
import { IconProps } from './IconProps'

const Icon = (props: IconProps) => {
  const venue = useVenue()

  if (venue === venues.grandTheatre) {
    return <IconGrandTheatreQc {...props} />
  } else {
    return <IconMtelus {...props} />
  }
}

export default Icon
