import { I18n, i18n, setupI18n } from '@lingui/core'
import { en, fr } from 'make-plural/plurals'

import * as enCa from '../locales/en-CA/messages'
import * as frCa from '../locales/fr-CA/messages'

const defaultLocale = 'fr-CA'

const loadLocales = () => {
  i18n.loadLocaleData({
    'fr-CA': {plurals: fr},
    'en-CA': {plurals: en},
  })

  i18n.load({
    'fr-CA': frCa.messages,
    'en-CA': enCa.messages,
  })
}

const setLocale = (locale: string) => {
  i18n.activate(locale)

  if (document.documentElement) {
    document.documentElement.lang = locale
  }
}

const resetLocale = () => {
  if (i18n.locale !== defaultLocale) {
    setLocale(defaultLocale)
  }
}

const getPlurals = (locale: string) => {
  if (locale.includes('fr')) return fr

  return en
}

const getMessages = (locale: string) => {
  if (locale === 'fr-CA') return frCa.messages
  if (locale.includes('fr')) return frCa.messages

  return enCa.messages
}

export type Locale =
  | 'fr-CA'
  | 'en-CA'

const locales: Locale[] = [
  'fr-CA',
  'en-CA',
]

const i18ns: {[locale in Locale]: I18n} = {
  'fr-CA': setupI18n(),
  'en-CA': setupI18n(),
}

for (const locale of locales) {
  const i18n = i18ns[locale]

  i18n.loadLocaleData({
    [locale]: {plurals: getPlurals(locale)},
  })

  i18n.load({
    [locale]: getMessages(locale),
  })

  i18n.activate(locale)
}

const getFixedI18n = (locale: Locale) => {
  return i18ns[locale]
}

const getLocales = (): Locale[] => {
  return locales
}

// Helpers for debugging.

interface I18nWindow extends Window {
  setLocale?: typeof setLocale,
}

const win: I18nWindow = window

win.setLocale = setLocale

export {
  loadLocales,
  setLocale,
  resetLocale,
  getFixedI18n,
  getLocales,
}
