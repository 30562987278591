import { ReactNode } from 'react'

import { useVenue, venues } from '../hooks/venues'

import styles from './BorderedPage.module.css'
import Icon from './Icon'
import { IconType } from './IconProps'

type BorderedPageProps = {
  className?: string,
  icon?: IconType,
  children: ReactNode,
}

const MtelusBorderedPage = (props: BorderedPageProps) => {
  return (
    <div className={`${styles['container']} ${props.className ?? ''}`}>
      {
        props.icon !== undefined
          ? (
            <div className={styles['decoration-container']}>
              <div className={styles['decoration-box']}>
                <Icon icon={props.icon} />
              </div>
            </div>
          )
          : null
      }
      {props.children}
    </div>
  )
}

const GrandTheatreQcBorderedPage = (props: BorderedPageProps) => {
  return (
    <div className={`${styles['container']} ${props.className ?? ''}`}>
      {
        props.icon !== undefined
          ? (
            <div>
              <Icon icon={props.icon} />
              {props.children}
            </div>
          )
          : props.children
      }
    </div>
  )
}

const BorderedPage = (props: BorderedPageProps) => {
  const venue = useVenue()

  if (venue === venues.grandTheatre) {
    return <GrandTheatreQcBorderedPage {...props} />
  } else {
    return <MtelusBorderedPage {...props} />
  }
}

export default BorderedPage
