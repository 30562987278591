import { Trans } from '@lingui/macro'

import BilingualContent from '../../components/BilingualContent'
import BorderedPage from '../../components/BorderedPage'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue, venues } from '../../hooks/venues'

import styles from './Invalid.module.css'

const Invalid = () => {
  useRedirectToHomeTimeout()

  const venue = useVenue()

  let header
  let reason1
  let or
  let reason2
  switch (venue) {
    case venues.grandTheatre:
      header =
        <>
          Lecture erronée
        </>

      reason1 =
        <>
          L’intensité de la lumière ou le mouvement peuvent nuire à la lecture
          du code PRIMO. Nous vous invitons à essayer de nouveau.
        </>

      or =
        <>
          Ou
        </>

      reason2 =
        <>
          Il est aussi possible que le code présenté ne soit pas associé
          à un compte PRIMO actif. Afin de vérifier votre inscription, visitez le primotelus.ca
        </>

      break

    case venues.mtelus:
    default:
      header =
        <Trans>
          The QR code<br />does not appear to be valid.
        </Trans>

      reason1 =
        <Trans>
          It is possible that this is not a QR code associated with a member's account
        </Trans>

      or =
        <Trans>
          OR
        </Trans>

      reason2 =
        <Trans>
          Reading was not optimal due to excessive movement or insufficient
          brightness on your device.
        </Trans>

      break
  }

  return (
    <BorderedPage>
      <BilingualContent
        content={
          <>
            <h1 className={styles['header']}>
              {header}
            </h1>
            <p className={styles['not-found']}>
              {reason1}
            </p>
            <p className={styles['or']}>
              {or}
            </p>
            <p className={styles['bad-reading']}>
              {reason2}
            </p>
          </>
        }
      >
        {({mainLang, secondLang}) => (
          <div className={styles['container']}>
            <div className={styles['fr']}>
              {mainLang}
            </div>
            {
              secondLang === null
                ? null
                : (
                  <>
                    <hr className={styles['separator']} />
                    <div className={styles['en']}>
                      {secondLang}
                    </div>
                  </>
                )
            }
          </div>
        )}
      </BilingualContent>
    </BorderedPage>
  )
}

export default Invalid
