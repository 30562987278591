import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'

import BilingualContent from '../../components/BilingualContent'
import BorderedPage from '../../components/BorderedPage'
import { IconType } from '../../components/IconProps'
import { timeouts } from '../../constants/timeouts'
import { useQuery } from '../../hooks/query'
import { useRedirect } from '../../hooks/redirection'
import { useConfigureActionWithTimeoutGuard } from '../../hooks/timeout'
import { useVenue, venues } from '../../hooks/venues'

import styles from './CodeRead.module.css'

// Use this code if you want to work on this page, it prevents redirection.
const magicCode = 'stay'
const enableMagicCode = process.env['REACT_APP_ENABLE_MAGIC_CODES'] === 'true'

const successText =
  <Trans>
    Reading successful.
  </Trans>

const successTextGrandTheatreQc =
  <Trans>
    Reading successful
  </Trans>

// NOTE(pascal@brite4.com): At the time of writing, the user ID is a UUID.
const uuidRegex = /^[a-fA-F0-9]{8}-?[a-fA-F0-9]{4}-?[a-fA-F0-9]{4}-?[a-fA-F0-9]{4}-?[a-fA-F0-9]{12}$/

const Events = () => {
  const venue = useVenue()
  const query = useQuery()
  const redirectTo = useRedirect()
  const setTimeoutAction = useConfigureActionWithTimeoutGuard(timeouts.readSuccessMs)

  const [userId, setUserId] = useState<string | null>(null)

  const code = query.get('code')
  const kioskId = query.get('kuuid')
  useEffect(
    () => {
      const areValidCodes = () =>
        (enableMagicCode && code === magicCode) ||
          uuidRegex.test(code ?? '') ||
            (
              venue === venues.grandTheatre &&
              kioskId !== null &&
              uuidRegex.test(kioskId ?? '')
            )
      if (code == null || !areValidCodes()) {
        redirectTo.invalid()
        return
      }

      setTimeoutAction(() => redirectTo.events(code))
      setUserId(code)
    },
    [
      code,
      redirectTo,
      kioskId,
      venue,
      setTimeoutAction,
    ],
  )

  if (userId === null) return null

  let text
  switch (venue) {
    case venues.grandTheatre:
      text = successTextGrandTheatreQc
      break

    default:
      text = successText
      break
  }

  return (
    <BorderedPage icon={IconType.ThumbsUp}>
      <div className={styles['container']}>
        <BilingualContent
          content={text}
        >
          {({mainLang, secondLang}) => (
            <div className={styles['text-container']}>
              <div className={styles['fr']}>
                {mainLang}
              </div>
              {
                secondLang === null
                  ? null
                  : (
                    <>
                      <hr className={styles['separator']} />
                      <div className={styles['en']}>
                        {secondLang}
                      </div>
                    </>
                  )
              }
            </div>
          )}
        </BilingualContent>
      </div>
    </BorderedPage>
  )
}

export default Events
