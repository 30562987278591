import { Trans } from '@lingui/macro'

import BilingualContent from '../../components/BilingualContent'
import BorderedPage from '../../components/BorderedPage'
import { useRedirectToHomeTimeout } from '../../hooks/timeout'
import { useVenue, venues } from '../../hooks/venues'

import styles from './NetworkError.module.css'

const headerMtelus =
  <Trans>
    The server could not be reached.
  </Trans>

const messageMtelus =
  <Trans>
    We apologize for the situation. Please notify a member of our staff.
  </Trans>

const headerGrandTheatre =
  <>
    Erreur de connexion
  </>

const messageGrandTheatre =
  <>
    Nous sommes désolés, le serveur est actuellement hors de portée.
    Nous veillons à résoudre la situation dans les meilleurs délais.
  </>

const NetworkError = () => {
  useRedirectToHomeTimeout()

  const venue = useVenue()

  let header
  let message
  switch (venue) {
    case venues.grandTheatre:
      header = headerGrandTheatre
      message = messageGrandTheatre
      break

    default:
      header = headerMtelus
      message = messageMtelus
      break
  }

  return (
    <BorderedPage>
      <BilingualContent
        content={
          <>
            <h1 className={styles['header']}>
              {header}
            </h1>
            <p className={styles['text']}>
              {message}
            </p>
          </>
        }
      >
        {({mainLang, secondLang}) => (
          <div className={styles['container']}>
            <div className={styles['fr']}>
              {mainLang}
            </div>
            {
              secondLang === null
                ? null
                : (
                  <>
                    <hr className={styles['separator']} />
                    <div className={styles['en']}>
                      {secondLang}
                    </div>
                  </>
                )
            }
          </div>
        )}
      </BilingualContent>
    </BorderedPage>
  )
}

export default NetworkError
