import { Venue, venues } from '../types/Venue'
import { getVenue } from '../utils/venues'

const useVenue = (): Venue => {
  return getVenue()
}

export {
  venues,
  useVenue,
}
