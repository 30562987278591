import { useEffect, useRef } from 'react'

import { reload } from '../../utils/location'
import { getRemainingTimeMsUntilMidnight } from '../../utils/time'

import Carousel from './Carousel'
import ScanPrompt from './ScanPrompt'

const Home = () => {
  const refreshTimeoutIdRef = useRef<number | undefined>(undefined)

  useEffect(
    () => {
      window.clearTimeout(refreshTimeoutIdRef.current)
      refreshTimeoutIdRef.current = undefined

      const remainingTimeBeforeMidnight =
        getRemainingTimeMsUntilMidnight(new Date())

      const timeoutId = window.setTimeout(
        () => {
          refreshTimeoutIdRef.current = undefined
          reload()
        },
        remainingTimeBeforeMidnight,
      )

      refreshTimeoutIdRef.current = timeoutId

      return () => {
        window.clearTimeout(timeoutId)
      }
    },
    [],
  )

  return (
    <>
      <Carousel />
      <ScanPrompt />
    </>
  )
}

export default Home
