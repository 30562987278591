export type Event = {
  id: number,
  name: string,
  startDate: Date,
  roomName: string,
}

type EventDto = {
  id: number,
  name: string,
  start_date: string,
  room: string,
}

export type EventsDto = {
  events: EventDto[] | undefined,
}

export const mapFromDto = (dto: EventsDto): Event[] | null =>
  dto
    .events
    ?.map(event => ({
      id: event.id,
      name: event.name,
      startDate: new Date(event.start_date),
      roomName: event.room,
    }))
    ?? null
